import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" 경품수령지 "),_c('span',{staticClass:"asterisk"},[_vm._v("*")]),_c('span',{staticClass:"asterisk"},[_vm._v("주소 입력은 필수 입니다.")])]),_c(VTextField,{ref:"refZipcode",attrs:{"placeholder":"우편번호 검색","solo":"","readonly":"","height":_vm.formHeight,"required":""},on:{"click":function($event){return _vm.$emit('openSearch')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{attrs:{"x-small":"","depressed":"","rounded":""},on:{"click":function($event){return _vm.$emit('openSearch')}}},[_vm._v(" 우편번호")])]},proxy:true}]),model:{value:(_vm.userData.giftAddress.zipcode),callback:function ($$v) {_vm.$set(_vm.userData.giftAddress, "zipcode", $$v)},expression:"userData.giftAddress.zipcode"}}),_c(VTextField,{ref:"refCity",attrs:{"placeholder":"주소","solo":"","readonly":"","height":_vm.formHeight,"required":""},model:{value:(_vm.userData.giftAddress.city),callback:function ($$v) {_vm.$set(_vm.userData.giftAddress, "city", $$v)},expression:"userData.giftAddress.city"}}),_c(VTextField,{ref:"refStreet",attrs:{"placeholder":"상세 주소","solo":"","height":_vm.formHeight,"required":"","rules":[_vm.addressDetailRules.required]},model:{value:(_vm.userData.giftAddress.street),callback:function ($$v) {_vm.$set(_vm.userData.giftAddress, "street", $$v)},expression:"userData.giftAddress.street"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }