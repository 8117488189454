<template>
  <div class="terms">
    <h4>[12월 이벤트 참여안내]</h4>
    <br />
    •이벤트 진행기간: 2024년 12월 2일 ~ 12월 31일 <br />
    •경품발송: 당첨 시, 입력하신 주소로 경품이 발송됩니다. <br />
    •유의사항: 경품은 <strong>시술 받으신 본인만 수령 가능하며, 양도는 불가합니다.</strong><br />
    <span class="leftTab">※ 경품은 해외배송 불가합니다. <br /></span>
    <span class="leftTab">※ 기프티콘의 경우 010으로 시작하는 번호에 한해서만 발송 가능합니다. <br /></span>
    •참여조건: 정품 팁 1개당 1회만 참여 가능합니다. <br />
    <span class="leftTab">※ 동일한 정품 팁(코드)으로 중복참여는 불가합니다. <br /></span>
    <span class="leftTab">※ 부정확한 정보 입력 시, 당첨이 취소될 수 있으니 정확한 정보를 입력해 주세요. <br /></span>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style>
.terms li {
  list-style: disc;
}
.leftTab {
  margin-left: 20px;
}
</style>
