<template>
  <div v-if="step === 'login'" class="login">
    <div class="section-inner">
      <div class="login-header">
        <img
          class="top-logo"
          src="@/assets/images/common/thermage_logo.png"
          alt="app_logo"
        />
        <p class="sub-title" v-if="this.$route.params.value === 'EquipmentEnterPage'">
          <span>A/S 접수 페이지 입니다.</span><br />
          <span>제품의 시리얼 넘버와 정보</span><br />
          <span>확인을 위해 로그인 해주세요.</span>
        </p>
        <p class="sub-title" v-else>
          <span>본 자료실은 보건의료전문가만</span><br />
          <span>열람 가능 합니다.</span>
        </p>
      </div>
      <v-form ref="form">
        <v-text-field
          solo
          height="52"
          label="아이디"
          required
          v-model="loginId"
          :rules="[idRules.required, idRules.email]"
        ></v-text-field>

        <v-text-field
          solo
          height="52"
          label="비밀번호"
          required
          v-model="password"
          :rules="[passwordRules.required, passwordRules.counter]"
          :append-icon="pwShow ? 'mdi-eye' : 'mdi-eye-off'"
          :type="pwShow ? 'text' : 'password'"
          @click:append="pwShow = !pwShow"
        ></v-text-field>
        <div class="link-group">
          <v-btn text @click="$router.push('/find_id')"> 아이디 찾기</v-btn>
          |
          <v-btn text @click="$router.push('/find_pw')"> 비밀번호 찾기</v-btn>
        </div>
        <v-btn
          class="mb-3"
          color="primary"
          x-large
          depressed
          block
          @click="onClickSignIn"
          >로그인</v-btn
        >
      </v-form>
      <!-- 카카오 로그인 임시가림 처리 -->
      <v-btn
        class="btn-kakao mb-3"
        x-large
        depressed
        block
        @click="kakaoLogin()"
        >카카오 로그인</v-btn
      >
      <v-btn x-large text block @click="moveTo('signup')">회원가입</v-btn>
    </div>

    <!-- 확인팝업 -->
    <ConfirmDialog
      ref="ConfirmDialog"
      :useBtnOK="true"
      :useBtnCancel="true"
      @clickOk="ConfirmDialog_clickOKHandler"
    />
  </div>
</template>

<script>
// import Vue from "vue";
import ConfirmDialog from "@/components/common/dialog/ConfirmDialog.vue";
import { mapActions } from "vuex";
const rulesService = require("@/utils/rules.js");

export default {
  components: { ConfirmDialog },
  data() {
    return {
      step: "login",
      userData: null,
      currentMode: "",
      kakaoAccount: null,
      isDev: process.env.NODE_ENV === "development",
      pwShow: false,
      loginId: "",
      password: "",
      idRules: rulesService.idRules(),
      passwordRules: rulesService.passwordRules(),
    };
  },
  created() {
    console.log(this.$route.params.value);
  },
  mounted() {
    // 개발모드에서 처리
    if (this.isDev) {
      this.loginId = "t@predict.kr";
      this.password = "185975";
      // this.loginId = "t4@predict.kr";
      // this.password = "predict6";
      // this.loginId = "m.oh2@predict.kr";
      // this.password = "12345678!";
    }
  },
  methods: {
    onClickSignIn() {
      if (!this.$refs.form.validate()) return;
      this.$emit("onClickSignInEmit", this);
    },
    moveTo(to) {
      this.$emit("moveToEmit", to);
    },
    /** 카카오로그인 처리 */
    kakaoLogin() {
      const _self = this;
      // 등록된 앱의 JavaScript key -> main.js에서 처리
      // const jsKey = "75895f597bdf80fa525efbbdaa1a86c8";
      // if (!window.Kakao.isInitialized()) {
      //   window.Kakao.init(jsKey);
      //   console.log(window.Kakao.isInitialized());
      // }
      window.Kakao.Auth.login({
        // scope: "profile, age_range",
        // scope: "profile_image, account_email",
        // 정보를 받으려면~ scope 정보 제거
        scope: "",
        success: function (response) {
          window.Kakao.Auth.setAccessToken(response.access_token);
          console.log(`is set?: ${window.Kakao.Auth.getAccessToken()}`);
          _self.getKakaoAccount();
        },
      });
    },
    getKakaoAccount() {
      window.Kakao.API.request({
        url: "/v2/user/me",
        success: (response) => {
          console.log("response", response);
          this.kakaoAccount = response.kakao_account;

          //0.아이디확인 -> 없으면:회원가입 || 있으면: -> 로그인
          this.kakaoAccount.endpoint = "kakaoCheckId";
          this.kakaoAccount.queryMethod = "POST";
          this.saveDataPreLoginQuery(this.kakaoAccount).then((res) => {
            console.log("", res);

            // 회원가입 처리
            if (res.count === 0) {
              console.log("회원가입해야 함");
              const content = "회원가입 하겠습니까?";
              this.$refs.ConfirmDialog.open(content);
              return;
            }
            // 로그인 처리
            if (res.count > 0) {
              if (res.data.signupPath === "" || res.data.signupPath === null) {
                alert("일반회원으로 가입되어있습니다.");
                return;
              }
              this.loginId = res.data.loginId;
              this.password = "kakao_email_" + res.data.loginId;
              this.signupPath = "kakao";
              this.$emit("onClickSignInEmit", this);
            }
          });

          // alert("로그인 성공!");
        },
        fail: (error) => {
          console.log(error);
        },
      });
    },
    kakaoLogout() {
      window.Kakao.Auth.logout((res) => {
        console.log(res);
      });
    },
    ConfirmDialog_clickOKHandler() {
      // 회원가입페이지로 이동
      this.$emit("onKakaoSignUpEmit", this.kakaoAccount);
    },
    // findIdPassWordAction(to) {
    //   this.status = to;
    //   this.currentMode = to;
    //   this.step = "dannal";
    //   this.userData = {
    //     name: "",
    //     cell: "",
    //   };
    // },
    // headerCloseClickHandler() {
    //   this.step = "login";
    // },
    // 본인인증 후
    // successPassAuthHandler(checkedUserData) {
    //   console.log("", this.currentMode, checkedUserData);
    //   this.step = "";
    //   if (checkedUserData.count === 0) {
    //     Vue.$toast.open({
    //       position: "top",
    //       message: `회원가입된 정보가 존재하지 않습니다.`,
    //       type: "info",
    //       duration: 1000,
    //       dismissible: true,
    //     });
    //     window.location.href = "/";
    //     return;
    //   }

    //   this.$emit("moveToEmit", this.currentMode, checkedUserData);
    //   // this.step = "";
    //   // // console.log("", e.imp_uid);
    //   // this.imp_uid = e.imp_uid;
    //   // this.getDannalInfo(this).then((res) => {
    //   //   // 이름 생년월일 성별로 검색 -> 아이디 검색
    //   //   console.log("", res);
    //   //   this.checkType = "Id/Pw";
    //   //   this.name = res.name;
    //   //   this.birthday = res.birthday.replaceAll("-", "");
    //   //   this.gender = res.gender.toUpperCase();
    //   //   this.getCheckIdPw(this).then((res) => {
    //   //     console.log("", res);
    //   //     this.$emit("moveToEmit", this.currentMode, res);
    //   //   });
    //   // });
    // },
    ...mapActions(["saveDataPreLoginQuery"]),
  },
};
</script>

<style lang="scss">
.login {
  padding: 8rem 0 12rem;
  .section-inner {
    width: 65vw;
    max-width: 800px;
  }
  .login-header {
    padding: 0 1rem 2.5rem;
    text-align: center;
    img {
      width: 170px;
    }
    .input-guide {
      margin-top: 1.5rem;
      color: $primary-color;
    }
  }
  .v-btn.btn-kakao {
    background: #f6d523;
  }

  .link-group {
    margin-bottom: 1rem;
    text-align: right;
    .v-btn {
      text-decoration: underline;
      font-weight: 700;
    }
  }
}
@media (max-width: 768px) {
  div.login {
    padding: 4rem 0 2rem;
  }
}
</style>
