<template>
  <div class="page submitted">
    <div class="page-section">
      <div class="section-inner">
        <div class="section-title">
          <img
            class="top-logo"
            src="@/assets/images/common/thermage_logo.png"
            alt="thermage_logo"
          />
          <p class="sub-title">
            써마지<sup>®</sup> 정품팁을 사용해주셔서 감사합니다.
          </p>
          <h1 class="main-title primary--text">
            [12월 이벤트 안내]
          </h1>
          <h2>
            <div>이벤트에 참여해 주셔서 감사드립니다.</div>
            <div>이벤트 종료 후, 경품 담청자는 선착순으로 선정되며, 개별 문자로 안내드릴 예정입니다.</div>
            <div>참여해 주신 모든 분들께 감사드립니다.</div>
          </h2>
        </div>

        <div class="submit-btn-wrap">
          <v-btn
            depressed
            color="primary"
            x-large
            block
            @click="$router.push('/')"
            >홈 화면으로 돌아가기</v-btn
          >
        </div>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <p class="part-number mt-6">KTMG-20240122-042, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss">
.submitted {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    .submit-btn-wrap {
      margin-top: 40px;
    }
    .section-inner .section-title {
      .main-title {
        font-size: 34px;
      }
    }
    @media (max-width: 768px) {
      div.section-inner .section-title {
        .main-title {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
