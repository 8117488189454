<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            경품수령지
            <span class="asterisk">*</span>
            <span class="asterisk">주소 입력은 필수 입니다.</span>
          </v-list-item-title>
          <v-text-field
              placeholder="우편번호 검색"
              ref="refZipcode"
              solo
              readonly
              :height="formHeight"
              required
              v-model="userData.giftAddress.zipcode"
              @click="$emit('openSearch')"
          >
            <template v-slot:append>
              <v-btn x-small depressed rounded @click="$emit('openSearch')">
                우편번호</v-btn
              >
            </template>
          </v-text-field>
          <v-text-field
              placeholder="주소"
              ref="refCity"
              solo
              readonly
              :height="formHeight"
              required
              v-model="userData.giftAddress.city"
          />
          <v-text-field
              placeholder="상세 주소"
              ref="refStreet"
              solo
              :height="formHeight"
              required
              v-model="userData.giftAddress.street"
              :rules="[addressDetailRules.required]"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import modelService from "@/utils/dataModel";
import rulesService from "@/utils/rules";

export default {
  components: {},
  props: ['userData'],
  data() {
    return {
      formHeight: 52,
      addressDetailRules: rulesService.addressDetailRules(),
    };
  },
};
</script>
<style lang="scss">
div.v-list:not(.v-select-list) {
  background: transparent !important;

  .v-list-item {
    padding: 0;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__title {
      //font-size: 14px;
      //font-weight: bold;
      //padding: 0 15px 5px;
      //color: #108000;

      .asterisk {
        color: #de0043;
      }
    }

    &.email {
      .inner-content {
        display: flex;

        span {
          padding: 15px 6px;
          font-weight: 700;
        }
      }
    }

    .v-input {
      .v-input__slot {
        .v-btn {
          font-weight: 700;
          background-color: #43163e;
          color: #ffffff;
          font-size: 12px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }

    .v-input {
      & ~ .input-guide {
        display: block;
        font-size: 12px;
        padding: 0 10px 20px;
        margin-top: -5px;
      }

      &.error--text {
        & ~ .input-guide {
          display: none;
        }
      }
    }
  }
}
</style>
